exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-diagrameditor-tsx": () => import("./../../../src/pages/projects/diagrameditor.tsx" /* webpackChunkName: "component---src-pages-projects-diagrameditor-tsx" */),
  "component---src-pages-projects-milestone-creative-community-tsx": () => import("./../../../src/pages/projects/milestone-creative-community.tsx" /* webpackChunkName: "component---src-pages-projects-milestone-creative-community-tsx" */),
  "component---src-pages-projects-pocaktudor-app-tsx": () => import("./../../../src/pages/projects/pocaktudor-app.tsx" /* webpackChunkName: "component---src-pages-projects-pocaktudor-app-tsx" */),
  "component---src-pages-projects-prime-tsx": () => import("./../../../src/pages/projects/prime.tsx" /* webpackChunkName: "component---src-pages-projects-prime-tsx" */),
  "component---src-pages-projects-ragegames-tsx": () => import("./../../../src/pages/projects/ragegames.tsx" /* webpackChunkName: "component---src-pages-projects-ragegames-tsx" */),
  "component---src-pages-projects-rc-tsx": () => import("./../../../src/pages/projects/rc.tsx" /* webpackChunkName: "component---src-pages-projects-rc-tsx" */),
  "component---src-pages-projects-robotarm-tsx": () => import("./../../../src/pages/projects/robotarm.tsx" /* webpackChunkName: "component---src-pages-projects-robotarm-tsx" */),
  "component---src-pages-projects-virussim-tsx": () => import("./../../../src/pages/projects/virussim.tsx" /* webpackChunkName: "component---src-pages-projects-virussim-tsx" */),
  "component---src-templates-project-info-tsx": () => import("./../../../src/templates/project-info.tsx" /* webpackChunkName: "component---src-templates-project-info-tsx" */)
}

